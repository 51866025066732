import { Component, OnInit } from '@angular/core';
import { XpoAuthenticationService, XpoAuthSwitchApiService } from '@xpo/ngx-auth';
import { Observable } from 'rxjs';
import { filter, switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-app-switcher',
  templateUrl: './app-switcher.component.html',
  styleUrls: ['./app-switcher.component.scss'],
})
export class AppSwitcherComponent implements OnInit {
  placeholderLogo: string;
  applications$: Observable<any>;

  constructor(private authService: XpoAuthenticationService, private switchApiService: XpoAuthSwitchApiService) {}

  ngOnInit(): void {
    this.applications$ = this.authService.getUser$().pipe(
      filter((v) => !!v && !!v.profile),
      switchMap((user) => {
        return this.switchApiService.getAllUserApplications(user, true);
      }),
      map((applications) => {
        return applications.filter((application) => {
          return application.clientId !== 'ngx-auth-app-switcher';
        });
      })
    );
  }
}
