import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ConfigLoader, ConfigModule, ConfigService } from '@ngx-config/core';
import { ConfigHttpLoader } from '@ngx-config/http-loader';
import { XPO_AUTH_CONFIG, XpoAuthConfig, XpoAuthEventHandler, XpoAuthModule } from '@xpo/ngx-auth';

export function configFactory(http: HttpClient): ConfigLoader {
  return new ConfigHttpLoader(http, './app-settings.json');
}

export function authConfigFactory(configService: ConfigService): XpoAuthConfig {
  return new XpoAuthConfig({
    appCode: configService.getSettings('appCode'),
    scopes: configService.getSettings('scopes'),
    isProd: configService.getSettings('isProd'),
  });
}

@NgModule({
  imports: [
    CommonModule,
    ConfigModule.forRoot({
      provide: ConfigLoader,
      useFactory: configFactory,
      deps: [HttpClient],
    }),
    XpoAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [ConfigService],
    }),
  ],
})
export class CoreModule {}
