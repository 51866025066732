import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';

import { AppSwitcherComponent } from './app-switcher/app-switcher.component';

const routes: Routes = [{ path: '', canActivate: [XpoAuthenticationGuard], component: AppSwitcherComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
