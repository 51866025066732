import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material';
import { XpoShellModule } from '@xpo/ngx-core';
import { XpoAuthModule } from '@xpo/ngx-auth';

import { CoreModule } from '../core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSwitcherComponent } from './app-switcher/app-switcher.component';

@NgModule({
  declarations: [AppComponent, AppSwitcherComponent],
  imports: [BrowserModule, BrowserAnimationsModule, CoreModule, MatIconModule, XpoShellModule, XpoAuthModule, AppRoutingModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
